<template>
    <div> <!-- do not remove empty root element. solves hover shadow delay in grid -->
        <div 
            class="
                panel
                hover:shadow-2xl 
                h-full
                transition 
                duration-200 
                ease-in-out
                flex
                flex-col
                cursor-pointer
                
                text-sm
                md:text-base
            "
            @click="$emit('edit', customer);">
            
            <div 
                class="
                    flex items-center 
                    md:mb-8 
                ">
                
                <div class="flex-grow flex">
                    <i 
                        v-if="customer.owner && customer.owner.username && user && user.username == customer.owner.username"
                        class="fas fa-star text-orange-500 mr-4 text-lg"
                        title="You are the creater of this customer"
                    />
                    
                    <div class="leading-tight text-red-500" v-if="customer.deletedAt">
                        {{$t('general.deleted')}} {{ customer.deletedAt | moment('from') }}
                    </div>
                    <div class="leading-tight text-gray-500" v-else>
                        <span v-if="customer.createdAt != customer.updatedAt">
                            {{$t('general.updated')}} {{ customer.updatedAt | moment('from') }}
                        </span>
                        <span v-else>
                            {{$t('general.created')}} {{ customer.createdAt | moment('from') }}
                        </span>
                    </div>
                </div>
                <div class="flex-none ml-1">
                    <div 
                        v-if="customer.isCompany"
                        class="px-2 py-1 rounded capitalize bg-cyan text-white">
                        {{$t('customers.company')}}
                    </div>
                    <div 
                        v-else
                        class="px-2 py-1 rounded capitalize bg-green-500 text-white">
                        {{$t('customers.personal')}}
                    </div>
                </div>
            </div>
            
            <h2 class="text-black leading-none">
                <span v-if="customer.isCompany">{{customer.name}}</span>
                <span v-else-if="primaryContact">{{primaryContact.name}}</span>
            </h2>
            
            <div 
                class="
                    grid
                    grid-cols-2
                    gap-8
                    
                    md:block
                    mt-2
                ">
                
                <div 
                    v-if="primaryContact"
                    class="">
                    <!-- bg-red-200 -->
                    
                    <h4 class="hidden md:block">{{$t('customers.contactPerson')}}</h4>
                    
                    <div 
                        v-if="primaryContact.name" 
                        class="truncate">
                        {{primaryContact.name}}
                    </div>
                    
                    <div 
                        v-if="primaryContact.email" 
                        class="truncate">
                        <!-- <i class="fas fa-envelope w-3 h-3 text-gray-300 inline-block mr-2" />  -->
                        {{primaryContact.email}}
                    </div>
                    
                    <div 
                        v-if="primaryContact.phone" 
                        class="truncate">
                        <!-- <i class="fas fa-phone w-3 h-3 text-gray-300 inline-block mr-2" />  -->
                        {{primaryContact.phone}}
                    </div>
                </div>
                <div 
                    v-if="customer.street"
                    class="">
                    
                    <h4 class="hidden md:block">{{$t('customers.address')}}</h4>
                    
                    <div>
                        {{customer.street}}<br>
                        {{customer.postcode}} {{customer.city}}<br>
                        {{customer.country}}<br>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        customer: {
            type: Object,
            required: true,
        }
    },
    computed: {
        user(){
            return this.$store.getters.getUser;
        },
        
        primaryContact(){
            if (
                this.customer.contacts && 
                this.customer.contacts.length) {
                
                return this.customer.contacts.find(c => c.isPrimary) || this.customer.contacts[0];
            }
            else {
                return null
            }
            // <!-- customer.contacts && customer.contacts.length -->    
        },
        
        logo(){
            if (!this.customer.logo) {
                return false;
            }
            return process.env.VUE_APP_FILES_ENDPOINT + '/'+this.accountId+'/' + this.customer.logo.fileId +'.'+ this.customer.logo.extension;
        }
    }
}
</script>

<style lang="scss" >
    
</style>
